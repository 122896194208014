import { Card } from './card';
import {
  AppGrid,
  Divider,
  Header,
  MyApps,
  MyAppsContainer,
  NoApps,
  NoAppsContainer,
  SuggestedAppsContainer,
  SubscribedAppGrid,
} from './styles';
import { useAppsData } from './hooks/useAppsData';
import { useDefaultBusiness } from './hooks/useDefaultBusiness';
import { selectedBusinessSelector } from '@/api/store/slices/businessSlice';
import { useSelector } from 'react-redux';

export const Apps = () => {
  const selectedBusiness = useSelector(selectedBusinessSelector);

  const { myProducts, products, isLoading } = useAppsData();

  if (isLoading) return <MyApps data-testid="myApps-loading" />;

  const renderMyAppsContent = () => {
    if (!selectedBusiness.id) {
      return (
        <NoAppsContainer data-testid="noApps-container">
          <NoApps>
            <img src="/info.svg" alt="Info" data-testid="noApps-info-icon" />
            You currently don't have any businesses associated with your
            account.
          </NoApps>
        </NoAppsContainer>
      );
    } else if (myProducts.length === 0) {
      return (
        <NoAppsContainer data-testid="noSubscribedApps-container">
          <NoApps>
            <img
              src="/info.svg"
              alt="Info"
              data-testid="noSubscribedApps-info-icon"
            />
            You haven’t subscribed to any Series products yet. Explore the
            Series suite of applications ...
          </NoApps>
        </NoAppsContainer>
      );
    } else {
      return (
        <MyAppsContainer data-testid="subscribedApps-container">
          <SubscribedAppGrid data-testid="subscribedApps-grid">
            {myProducts.map((app) => (
              <Card
                key={app.name}
                data={{ ...app, subscribed: true }}
                data-testid={`subscribedApp-${app.name}`}
              />
            ))}
          </SubscribedAppGrid>
        </MyAppsContainer>
      );
    }
  };

  return (
    <>
      <MyApps data-testid="myApps-container">
        <Header data-testid="myApps-header">Shortcuts</Header>
        <Divider />
        {renderMyAppsContent()}
      </MyApps>
      {products.length > 0 && (
        <SuggestedAppsContainer data-testid="suggestedApps-container">
          <Header>Suggested</Header>
          <Divider />
          <AppGrid data-testid="suggestedApps-grid">
            {products.map((product) => (
              <Card
                key={product.name}
                data={product}
                data-testid={`suggestedApp-${product.name}`}
              />
            ))}
          </AppGrid>
        </SuggestedAppsContainer>
      )}
    </>
  );
};
